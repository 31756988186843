import { GetServerSidePropsContext, NextPage } from 'next'
import { useEffect, useState } from 'react'
import tracking, { TrackingContentGroup, TrackingUserType } from 'src/tracking'
import { selectors } from 'src/redux'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import {
  isMaintenance,
  set503Header,
  setDefaultCacheHeader,
  setNoCacheHeader,
  ssrPersistedQuery,
  transformSeoToMetas,
} from '../helpers/SSRHelpers'
import { initWithLangAndLabels } from '../i18n'
import { pageBySlug } from '../graphql/Services/Page/queries'
import Template from '../templates/PageTemplate'
import Blocks from '../relay/Blocks'
import { BreadcrumbProps } from '../components/molecules/Breadcrumb'
import useBreadcrumb from '../relay/Breadcrumb/useBreadcrumb'
import useHeader from '../relay/Header/useHeader'
import useFooter from '../relay/Footer/useFooter'
import { Configuration } from '../graphql/Services'
import {
  GetServerSidePropsReturnType,
  PageProps as CommonPageProps,
} from '../relay/Common/PageProps'
import Router, { routes } from '../routes/Router'
import APP from '../configuration/app'
import useAuthPopin from '../relay/Common/useAuthPopin'
import AuthPopin from '../components/molecules/AuthPopin'
import BugsnagHelpers from '../helpers/BugsnagHelpers'
import { adPropsTransformer } from '../relay/Common/adPropsTransformer'

type PageProps = CommonPageProps & {
  openAuthPopin: boolean
}

const Page: NextPage<PageProps> = ({
  pageData,
  configuration,
  openAuthPopin,
}) => {
  const router = useRouter()
  const [shouldOpenAuthPopin, setShouldOpenAuthPopin] = useState(
    openAuthPopin || false
  )

  useEffect(() => {
    const hash = router.asPath.split('#')[1]
    if (hash === 'connexion' || router.query?.openAuthPopin === '1') {
      setShouldOpenAuthPopin(true)
    }
  }, [router.asPath, router.query?.openAuthPopin])

  const breadcrumbProps: BreadcrumbProps = useBreadcrumb(
    pageData?.slug === '/'
      ? undefined
      : [
          ...(pageData?.parent?.title
            ? [{ label: pageData.parent.title, href: pageData.parent?.slug }]
            : []),
          { label: pageData?.title },
        ]
  )
  const user = useSelector(selectors.auth.user)

  const authPopinProps = useAuthPopin({
    openAuthPopin: shouldOpenAuthPopin,
  })

  useEffect(() => {
    tracking.page({
      group: pageData?.template
        ? (TrackingContentGroup as any)[`${pageData?.template}`]
        : '',
      userType:
        user && user?.id ? TrackingUserType.MEMBER : TrackingUserType.VISITOR,
      userId: user?.id ?? '',
    })
  }, [pageData, user])

  const adProps = adPropsTransformer(pageData, configuration, true)

  return (
    <Template
      breadcrumbProps={breadcrumbProps}
      headerProps={useHeader(
        configuration?.header,
        configuration?.footer?.socialLinks
      )}
      footerProps={useFooter(configuration?.footer)}
      adProps={adProps}
    >
      {pageData && (
        <Blocks
          blocks={pageData?.blocks}
          pagesByTemplate={configuration?.pagesByTemplate}
          template={pageData?.template}
        />
      )}
      <AuthPopin {...authPopinProps} />
    </Template>
  )
}

export default Page

export async function getServerSideProps(
  context: GetServerSidePropsContext
): GetServerSidePropsReturnType<PageProps> {
  const i18n = initWithLangAndLabels('fr', {})
  try {
    const slug: string =
      '/' +
      (Array.isArray(context?.params?.slug) && context?.params?.slug
        ? context.params.slug.join('/')
        : context?.params?.slug ?? '')

    if (slug.indexOf('/_next') === 0) {
      return {
        notFound: true,
      }
    }

    const openAuthPopin = context?.query?.openAuthPopin

    const [pageRs, configurationRs] = await Promise.all([
      ssrPersistedQuery(pageBySlug, { slug }),
      ssrPersistedQuery(Configuration.queries.configuration),
    ])

    const pageData = pageRs?.data
    const configuration = configurationRs?.data ?? null
    const isHighCoMaintenanceEnabled =
      configuration?.highco?.maintenance && slug === APP.REDUCTION_SLUG

    if (!configuration || pageRs?.errors) {
      console.log('pageRs errors', pageRs?.errors)
      set503Header(context)
      return {
        props: {
          i18n,
          error: true,
        },
      }
    }

    if (isMaintenance(configuration) || isHighCoMaintenanceEnabled) {
      set503Header(context)
    } else {
      setDefaultCacheHeader(context)
    }

    if (!pageData) {
      setNoCacheHeader(context)
      return {
        notFound: true,
        props: {
          i18n,
          configuration,
        },
      }
    }

    return {
      props: {
        i18n,
        configuration,
        metas: transformSeoToMetas(
          slug === '/'
            ? Router.getRouteUrl(routes.home)
            : Router.getRouteUrl(routes.page, context?.params),
          pageData?.seo
        ),
        pageData,
        highCoMaintenanceEnabled: isHighCoMaintenanceEnabled,
        openAuthPopin: openAuthPopin === '1',
      },
    }
  } catch (e: any) {
    BugsnagHelpers?.notify(new Error('Page SSR Error'), (event) => {
      const { resolvedUrl, query, params } = context
      event.addMetadata('originalError', {
        error: e,
        message: e.message,
        stack: e?.stack,
      })
      event.addMetadata('context', { resolvedUrl, query, params })
    })

    set503Header(context)
    return {
      props: {
        i18n,
        error: true,
      },
    }
  }
}
